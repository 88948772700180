<template>
  <svg
    width="7px"
    height="11px"
    viewBox="0 0 7 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon / caret-right / blue</title>
    <g
      id="Home-(v2)-NEW"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Tablet"
        transform="translate(-719.000000, -564.000000)"
        fill="currentColor"
      >
        <g
          id="FETAURED-SPECIALS"
          transform="translate(-6.000000, 557.000000)"
        >
          <g
            id="Featured-specials-header"
            transform="translate(6.000000, 0.000000)"
          >
            <g
              id="arrows"
              transform="translate(384.000000, 13.000000) scale(1, -1) translate(-384.000000, -13.000000) translate(34.000000, 1.000000)"
            >
              <g
                id="Group-14"
                transform="translate(676.000000, 0.000000)"
              >
                <g
                  id="icon-/-caret-right-/-blue"
                  transform="translate(9.600000, 7.542857)"
                >
                  <path
                    id="Fill-16"
                    d="M5.31287453,5.14289796 C5.08242152,5.36692463 4.70864374,5.36692463 4.47795461,5.14289796 L0.173016847,0.975544708 C-0.0576722822,0.751518038 -0.0576722822,0.390332182 0.173016847,0.166305513 C0.403469856,-0.0554351709 0.777247635,-0.0554351709 1.00770064,0.166305513 L5.31287453,4.33365877 C5.54332754,4.55768544 5.54332754,4.91887129 5.31287453,5.14289796 Z M0.173016847,9.43369449 C-0.0576722822,9.20966782 -0.0576722822,8.84848196 0.173016847,8.62445529 L2.99795516,5.89041553 C3.4590973,5.44464817 4.20665286,5.44464817 4.66755888,5.89041553 L1.00770064,9.43369449 C0.777247635,9.65543517 0.403469856,9.65543517 0.173016847,9.43369449 L0.173016847,9.43369449 Z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
