<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10Z"
      stroke="black"
    />
    <line
      x1="10"
      y1="4"
      x2="10"
      y2="16"
      stroke="black"
      stroke-width="2"
    />
    <line
      x1="16"
      y1="10"
      x2="4"
      y2="10"
      stroke="black"
      stroke-width="2"
    />
  </svg>
</template>
