<script>
  import { mapGetters, mapActions } from 'vuex'

  /**
   * A renderless component that pulls shopping cart from vuex store and passes it to child old__components
   *
   * ***********
   * Passing cart object:
   * ************
   * <x-cart v-slot="cart">
   *   <h1> heres the cart in json format </h1>
   *   <span>{{ cart.cart }}</span>
   * </x-cart>
   *
   * ************
   * Passing list of discounts
   * ************
   * <x-cart v-slot="cart">
   *   <h1> heres an array of discount codes for the current user </h1>
   *   <span>{{ cart.discounts }}</span>
   * </x-cart>
   *
   * ************
   * Fetching cart on mount
   * ************
   * if you set the :refresh prop to true, the shopping cart will be fetched from the server on component mount
   *
   */

  export default {
    name: 'XCart',

    props: {
      /**
       * If true cart will be fetched from server when component is mounted
       */
      refresh: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters('cart', ['xShoppingCart', 'xLoyaltyDiscounts', 'xTotalCartQuantity', 'xCartLoaded']),

      /**
       * A helper object containing all coupon functions
       * @returns {{add, remove}}
       */
      coupon() {
        return {
          add: this.xAddCoupon,
          remove: this.xRemoveCoupon,
          removeAll: this.xRemoveAllCoupons,
          coupons: this.xLoyaltyDiscounts,
          applied: this.xShoppingCart?.coupons
        }
      },

      /**
       * A helper object containing cart and cart functions
       * @returns {{add, remove}}
       */
      cart() {
        return {
          ...this.xShoppingCart,
          coupon: this.coupon,
          itemCount: this.xShoppingCart?.items?.length || 0,
          totalQuantity: this.xTotalCartQuantity,
          refresh: this.xFetchCart,
          isLoaded: this.xCartLoaded
        }
      }
    },

    mounted() {
      if (this.refresh) {
        // console.log("********* x cart refresh is true so fetching cart from server");
        this.xFetchCart()
      }
    },

    methods: {
      ...mapActions('cart', [
        'xFetchCart',
        'xAddCoupon',
        'xRemoveCoupon',
        'xRemoveAllCoupons',
        'xAddToCart',
        'xRemoveFromCart'
      ])
    },

    /**
     * Renders component to the browser page directly instead of using a <template> element
     * @param h an internal vue function that creates a component
     */
    render(h) {
      if (this.xShoppingCart) {
        return this.$slots.default({
          xShoppingCart: this.cart,
          xLoyaltyDiscounts: this.xLoyaltyDiscounts,
          xAddCoupon: this.xAddCoupon,
          xRemoveCoupon: this.xRemoveCoupon,
          xRemoveAllCoupons: this.xRemoveAllCoupons,
          xCoupon: this.coupon,
          xAddToCart: this.xAddToCart,
          xRemoveFromCart: this.xRemoveFromCart,
          xTotalCartQuantity: this.xTotalCartQuantity,
          xCartLoaded: this.xCartLoaded
        })
      }

      return null
    }
  }
</script>
